<template>
  <div>
    <el-dialog
      title="查看用户联系方式"
      :visible.sync="addDialogVisible"
      direction="rtl"
      width="35%"
    >
      <el-form
        ref="addFormRef"
        label-width="80px"
        :model="editform"
        :rules="addFormRulue"
        size="small"
      >
        <el-form-item label="邮箱">
          <el-input v-model="editform.email" />
          <!-- <span>{{ editform.email }}</span> -->
        </el-form-item>
        <el-form-item label="电话">
          <el-input v-model="editform.phone" disabled />
        </el-form-item>
        <el-form-item label="QQ号码">
          <el-input v-model="editform.qq" />
        </el-form-item>

        <el-form-item label="微信号">
          <el-input v-model="editform.weixin" />
        </el-form-item>
      </el-form>

      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" size="samll" @click="edit">确 认</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  components: { upImg: () => import('../components/upImg/') },

  data() {
    var checkEmail = (rule, value, cb) => {
      // 这是验证邮箱的正则表达式
      const regemail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      if (regemail.test(value)) {
        return cb()
      }
      cb(new Error('请输入合格邮箱'))
    }
    var checkMoblle = (rule, value, cb) => {
      const regphone = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/
      if (regphone.test(value)) {
        return cb()
      }
      cb(new Error('请输入合法的手机号'))
    }
    return {
      options: [],
      serch_en: '',
      addEnData: {
        keyword: '',
        page: 1,
        pageSize: 20
      },
      loading: false,
      addDialogVisible: false,
      action: {
        action: 'user'
      },

      editform: {
        realName: '',
        nickname: '',
        sex: '',
        qq: '',
        email: '',
        phone: '',
        remark: '',
        avatar: '',
        backgroundImage: '',
        businessInfo: '',
        job: '',
        jobLevel: '',
        isShowCircles: 0,
        contactType: 2,
        showContacts: [],
        company: '',
        province: '',
        city: '',
        type: [],
        waId: [],
        weixin: '',
        business: [],
        ugId: 3,
        enterpriseId: ''
      },
      addFormRulue: {
        nickname: [
          { required: true, message: '请输入昵称', trigger: 'blur' }
          // {
          //   min: 3,
          //   max: 10,
          //   message: '用户的长度在3~10个字符直接',
          //   trigger: 'blur'
          // }
        ],
        realName: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        phone: [{ required: true, message: '请输入电话', trigger: 'blur' }],
        weixin: [{ required: true, message: '请输入微信', trigger: 'blur' }],
        businessInfo: [
          { required: true, message: '请输入业务介绍', trigger: 'blur' }
        ],
        // company: [
        //   { required: true, message: '请输入公司名称', trigger: 'blur' }
        // ],
        job: [{ required: true, message: '请输入职位', trigger: 'blur' }],
        enterpriseId: [
          { required: true, message: '请输入企业名称', trigger: 'blur' }
        ],
        // province: [{ required: true, message: '请输入城市', trigger: 'blur' }],
        contactType: [
          { required: true, message: '请输入联系方式', trigger: 'blur' }
        ],
        // type: [{ required: true, message: '请输入类型', trigger: 'blur' }],
        // business: [{ required: true, message: '请输入行业', trigger: 'blur' }],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          {
            min: 6,
            max: 15,
            message: '用户的长度在6~15个字符直接',
            trigger: 'blur'
          }
        ],
        email: [
          { required: true, message: '请输入邮箱', trigger: 'blur' },
          { validator: checkEmail, trigger: 'blur' }
        ],
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { validator: checkMoblle, trigger: 'blur' }
        ],
        serch_en_list: [],
        realname: [{ required: true, message: '请输入昵称', trigger: 'blur' }],
        enterpriseId: [
          { required: true, message: '请输入企业名称', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    async edit() {
      let { data: res } = await this.$http.post(
        '/admin/User/editContact',
        this.editform
      )
      if (res.errorCode == 200) {
        this.$message.success(res.message)
        this.addDialogVisible = false
      } else {
        this.$message.error(res.message)
      }
    },
    async getTheDetails(val) {
      let { data: res } = await this.$http.get('/admin/User/getContact', {
        params: {
          id: val
        }
      })
      if (res.errorCode == 200) {
        // console.log(val)
        this.editform = {
            ...res.data,
            id:val
        }
        this.addDialogVisible = true
      } else {
        this.$message.error(res.message)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.box {
  display: inline-block;
  margin-left: 15px;
}
</style>
